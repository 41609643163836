<template>
   <div class="title">
      <img class='back' src="https://dazhang-1255454858.cos.ap-nanjing.myqcloud.com/static/back.png"
         @click="$router.go(-1)">
      <h1>
         <img v-if="symbolVisible" src='https://dazhang-1255454858.cos.ap-nanjing.myqcloud.com/static/partySymbol.png'>
         {{ title }}
      </h1>
   </div>
</template>

<script>
export default {
   components: {},
   name: 'Title',
   props: {
      title: { type: String, },
      symbolVisible: { type: Boolean, default: false },
   },

   watch: {},
   computed: {},
   methods: {},
   created() { },
   mounted() { }
};
</script>
<style lang="scss" scoped>
body {
   margin: 0;
}

.title {
   // color: #2c3e50;
   position: relative;
   padding: 1rem;
   height: 5rem;
   box-sizing: border-box;

   .back {
      position: absolute;
      width: 4em;
      left: 5%;
      cursor: pointer;
      // top: 0;
   }

   h1 {
      margin: auto;
      width: fit-content;
      position: relative;
      // right: -10%;
      text-align: center;

      img {
         margin-right: 0.5em;
         width: 1.3em;
         display: inline;
         vertical-align: middle;
      }
   }
}
</style>