import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/introduction',
    name: 'introduction',
    component: () => import('../views/introduction/index.vue'),
  },
  // 平安大章
  {
    path: '/defender',
    name: 'defender',
    component: () => import('../views/defender/index.vue'),
    meta: {
      // notShowBkg 非红色背景为true
      // notShowBkg: true
    }
  },
  // 便民政务
  {
    path: '/goverServe',
    name: 'goverServe',
    component: () => import('../views/goverServe/index.vue'),
  },
  // 政务详情
  {
    path: '/goverServe/detail',
    name: 'goverServeDetail',
    component: () => import('../views/goverServe/detail.vue'),
  },

  // 政务详情
  {
    path: '/goverServe/detail1',
    name: 'goverServeDetail1',
    component: () => import('../views/goverServe/detail1.vue'),
  },

  // 网格管理
  {
    path: '/gridManage',
    name: 'gridManage',
    component: () => import('../views/gridManage/index.vue'),
  },
  {
    path: '/gridManage/detail',
    name: 'gridManage/detail',
    component: () => import('../views/gridManage/detail.vue'),
  },
  // 治理导图
  {
    path: '/gridManage/flatPic',
    name: 'flatPic',
    component: () => import('../views/gridManage/flatPic.vue'),
  },

  // 邻里中心
  {
    path: '/center',
    name: 'center',
    component: () => import('../views/center/index.vue'),
    meta: {
      // yellowBkg 背景图为黄色
      yellowBkg: true
    }
  },
  // 邻里中心详情页
  {
    path: '/center/detail',
    name: 'detail',
    component: () => import('../views/center/compoments/detail.vue'),
    meta: {
      // yellowBkg 背景图为黄色
      yellowBkg: true
    }
  },
  // 邻里中心7
  {
    path: '/center/seven',
    name: 'seven',
    component: () => import('../views/center/seven.vue'),
    meta: {
      // yellowBkg 背景图为黄色
      yellowBkg: true
    }
  },
  // 邻里中心n
  {
    path: '/center/n',
    name: 'n',
    component: () => import('../views/center/n.vue'),
    meta: {
      // yellowBkg 背景图为黄色
      notShowBkg: true
    }
  },
  // 积分管理
  {
    path: '/score',
    name: 'score',
    component: () => import('../views/score/index.vue'),
  },
  // 积分细则
  {

    path: '/score/scoreRule',
    name: 'scoreRule',
    component: () => import('../views/score/scoreRule.vue'),
  },
  // 兑换明细
  {

    path: '/score/exchangeDetail',
    name: 'exchangeDetail',
    component: () => import('../views/score/exchangeDetail.vue'),
  },


  // 文明实践
  {
    path: '/civilization',
    name: 'civilization',
    component: () => import('../views/civilization/index.vue'),
  },
  //志愿
  {
    path: '/civilization/serveTeam',
    name: 'serveTeam',
    component: () => import('../views/civilization/serveTeam/serveTeam.vue'),
  },
  // 实践
  {
    path: '/civilization/activity',
    name: 'activity',
    component: () => import('../views/civilization/activity/activity.vue'),
  },
  // 实践详情
  {
    path: '/civilization/activity/detail',
    name: 'activityDetail',
    component: () => import('../views/civilization/activity/detail.vue'),
  },
  // 全年计划
  {
    path: '/yearPlan',
    name: 'yearPlan',
    component: () => import('../views/civilization/serveTeam/yearPlan.vue'),
  },
  // 全年计划详情
  {
    path: '/yearPlan/detail',
    name: 'yearPlan/detail',
    component: () => import('../views/civilization/serveTeam/detail.vue'),
  },


  // 在线服务
  {
    path: '/onlineServe',
    name: 'onlineServe',
    component: () => import('../views/onlineServe/index.vue'),
  },
  // 智慧党建
  {
    path: '/party',
    name: 'party',
    component: () => import('../views/party/index.vue'),
  },
  // 党建新闻
  {
    path: '/party/newsDetail',
    name: 'partyNewsDetail',
    component: () => import('../views/party/news/newsDetail.vue'),
  },
  // 党员信息
  {
    path: '/memberInfo',
    name: 'memberInfo',
    component: () => import('../views/party/memberInfo.vue'),
  },
  // 流动党员
  {
    path: '/flowMember',
    name: 'flowMember',
    component: () => import('../views/party/flowMember.vue'),
  },
  // 党员条例
  {
    path: '/rule',
    name: 'rule',
    component: () => import('../views/party/rule/rule.vue'),
  },
  // 党员条例
  {
    path: '/rule/detail',
    name: 'ruleDetail',
    component: () => import('../views/party/rule/ruleDetail.vue'),
  },
  // 制度
  {
    path: '/regulation',
    name: 'regulation',
    component: () => import('../views/party/regulation.vue'),
  },
  // 入党申请
  {
    path: '/application',
    name: 'application',
    component: () => import('../views/party/application.vue'),
  },
  // 党费
  {
    path: '/partyFee',
    name: 'partyFee',
    component: () => import('../views/party/partyFee.vue'),
  },

]

const router = new VueRouter({
  routes
})

export default router
