<template>
    <div class="bkg">
        <img class="bkg-img" :src="bkgImgSrc">
        <div class="title">
            <slot name="titleImg">
                <img style="width: 40%;" src="https://dazhang-1255454858.cos.ap-nanjing.myqcloud.com/static/title.png">
            </slot>
            <!-- 时间 -->
            <div class="time" @click="fullScreen">
                <div>
                    <div> {{ date }}<span style=" margin-left: 0.5em;">星期{{ day }}</span></div>
                    <div style="text-align: right;">农历{{ lunar }}</div>
                </div>
                <div style=" font-size: 6vmin;">{{ clock }}</div>
            </div>
        </div>
        <div class="content-bkg">
            <slot></slot>
        </div>
        <div class="footer">技术支持：洛阳乐盈网络科技有限公司</div>

    </div>
</template>
<script>
import { getLunar } from 'chinese-lunar-calendar'
export default {
    name: 'Bkg',
    props: {
        bkgImgSrc: { type: String, default: () => 'https://dazhang-1255454858.cos.ap-nanjing.myqcloud.com/static/bkg.png' }
    },
    data() {
        return {
            lunar: '',
            // 日期
            date: '',
            clock: '',
            // 星期几
            day: '',
            // 计时器Id
            timeId: ''
        }
    },
    mounted() {
        this.prepareGetTime();
        this.getTime();
        // this.timeId = setInterval(this.getTime, 60 * 1000);
    },
    beforeDestroy() {
        clearInterval(this.intervalId);
        clearTimeout(this.timeId);
    },
    methods: {
        getTime() {
            let time = new Date();
            var now = new Date;
            time.setMinutes(time.getMinutes() + 1, 0, 0);
            let interval = time - now;

            // let interval = (60 - time.getSeconds()) * 1000;
            this.timeId = setTimeout(() => {
                let newTime = new Date();
                // console.log('getTime', this.clock, newTime.getSeconds())
                this.clock = this.doubleDigit(newTime.getHours()) + ':' + this.doubleDigit(newTime.getMinutes());
                this.intervalId = setInterval(this.prepareGetTime, 60 * 1000);
            }, interval);
        },
        prepareGetTime() {
            let time = new Date();
            this.clock = this.doubleDigit(time.getHours()) + ':' + this.doubleDigit(time.getMinutes());
            let nowDate = getLunar(time.getFullYear(), time.getMonth() + 1, time.getDate());
            this.date = time.getFullYear() + "/" + this.doubleDigit((time.getMonth() + 1)) + "/" + this.doubleDigit(time.getDate());
            this.day = '日一二三四五六'[time.getDay()];
            this.lunar = nowDate.dateStr;
            // console.log('prepareGetTime', this.clock, time.getSeconds())
        },
        doubleDigit(val) {
            // 方法一
            let valSting = '00' + val;
            return valSting.slice(-2)
            //方法二 return (''+val).padStart(2,'0')
        },
        fullScreen() {
            const element = document.documentElement; // 获取整个文档的根元素
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) { // Firefox浏览器
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) { // Chrome、Safari和Opera浏览器
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) { // IE/Edge浏览器
                element.msRequestFullscreen();
            }
        }
    }

}
</script>
<style lang="scss" scoped>
$margin: 10%;

body {
    margin: 0;
}



.bkg {
    // background-image: url(@/assets/bkg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;

    .bkg-img {
        position: absolute;
        width: 100vw;
        height: 100vh;
    }

    .content-bkg {
        background-color: #fff;
        position: absolute;
        width: calc(100% - 2*5%);
        // height: calc(100% - 2*$margin);
        height: 81%;
        bottom: 5%;
        left: 5%;
        border-radius: 20px;
    }

    .title {
        display: flex;
        color: #fff;
        position: relative;
        align-items: center;
        padding: 2% $margin/2;

        .time {
            background-color: rgba(255, 255, 255, 0.2);
            position: absolute;
            display: flex;
            right: $margin/2;
            font-size: 2.5vmin;
            font-family: serif;
            font-weight: 900;
            padding: 0.5em;
            align-items: center;
        }
    }

    .footer {
        position: absolute;
        bottom: 10px;
        color: #fff;
        // color: #B7B7B7;
        left: 50%;
        transform: translateX(-50%);
    }
}
</style>
