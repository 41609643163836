import axios from 'axios' // 引入axios
import qs from 'qs' // 引入qs模块，用来序列化post类型的数据，后面会提到
import { Loading, Message } from 'element-ui'
import Vue from 'vue'
import { log } from 'ezuikit-js'

// 环境的切换时自动切换域名
// let baseURL = '192.168.0.11:8081'
let baseURL = ''
if (process.env.NODE_ENV === 'production') {
   baseURL = window.config.baseUrl;
} else {
   baseURL = '/prod-api/'
}

let loadingInstance = null
const service = (options) => {
   return new Promise((resolve, reject) => {
      const instance = axios.create()

      // 设置请求地址
      if (baseURL) instance.defaults.baseURL = baseURL

      // 设置请求超时
      instance.defaults.timeout = 50000

      // axios请求转换器，在发送请求前修改参数
      instance.defaults.transformRequest = [function (data, config) {
         if (!config['Content-Type']) return qs.stringify(data)
         switch (config['Content-Type'].toLowerCase()) {
            case 'application/json;charset=utf-8':
               return JSON.stringify(data)
            case 'multipart/form-data;charset=utf-8':
               return data
            default:
               return qs.stringify(data)
         }
      }]

      // 请求拦截器
      instance.interceptors.request.use(config => {

         return config
      },
         error => { // 请求错误时做些事(接口错误、超时等)
            //  判断请求超时
            if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
               Message({
                  message: '请求超时',
                  type: 'warning'
               })
            }
            return Promise.reject(error) // 在调用的那边可以拿到(catch)你想返回的错误信息
         }
      )

      // 响应拦截器
      instance.interceptors.response.use(response => {
         // if (loadingInstance) loadingInstance.close()
         let data, res
         // IE9时response.data是undefined，因此需要使用response.request.responseText(Stringify后的字符串)
         if (typeof response.data === 'undefined') {
            data = response.request.responseText
         } else {
            data = response.data
         }
         switch (data.code) {
            case 200:
               res = data
               return res
            case 500: // 异常情况
               Message({
                  showClose: true,
                  message: data.msg,
                  type: 'error',
                  dangerouslyUseHTMLString: true
               })
               break
         }
         return false
      },
         err => {
            // if (loadingInstance) loadingInstance.close()
            console.log(err)
            Message({
               showClose: true,
               message: '请求错误',
               type: 'error'
            })
            return Promise.reject(err) // 返回接口返回的错误信息
         }
      )

      // 请求处理
      return instance(options)
         .then((res) => {
            resolve(res)
            return false
         })
         .catch((error) => {
            reject(error)
         })
   })
}

const http = {
   request(options) {
      if (!options.url) {
         return false
      }
      options.method = options.method ? options.method : 'post'
      // eslint-disable-next-line multiline-ternary
      options.headers = options.headers ? options.headers : {
         'Content-Type': 'application/json;charset=UTF-8'
      }
      return service(options)
   },

   get(url, params) {
      if (!url) {
         return false
      }
      // console.log('url', url, baseURL);
      return service({
         url: url,
         method: 'get',
         params: params,
         headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
         }
      })
   },

   post(url, data) {
      if (!url) {
         return false
      }
      return service({
         url: url,
         method: 'post',
         data: data,
         headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
         }
      })
   },

   upload(url, data, onUploadProgress) {
      if (!url || !data) {
         return false
      }
      loadingInstance = Loading.service({
         lock: true,
         text: '上传中'
      })
      return service({
         url: url,
         method: 'post',
         data: data,
         timeout: 0,
         closeLoading: false,
         headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8'
         },
         onUploadProgress: progressEvent => {
            const progress = progressEvent.loaded / progressEvent.total * 100 | 0
            loadingInstance.text = `已上传 ${progress - 1}% ，请耐心等待`
            if (onUploadProgress) onUploadProgress(progress)
         }
      })
   },
   upload_noprogress(url, data) {
      if (!url || !data) {
         return false
      }
      return service({
         url: url,
         method: 'post',
         data: data,
         headers: {
            'Content-Type': 'multipart/form-data;charset=UTF-8'
         }
      })
   },

   download_blob(url, data, headers) {
      if (!url) {
         return false
      }
      console.log('url', url)
      return service({
         url: url,
         method: 'get',
         data: data,
         timeout: 0,
         headers: headers || {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
         },
         responseType: 'blob'
      })
   },

   // 下载压缩包
   download_zip(url, data) {
      if (!url || !data) {
         return false
      }
      const JWT = 'Bearer ' + localStorage.getItem('kjcxfwlm_token')
      axios({
         method: 'post',
         url: baseURL + url,
         data,
         responseType: 'blob',
         headers: {
            Authorization: JWT,
            'Content-Type': 'application/json;charset=UTF-8'
         }// 设置header信息
      })
         .then(async res => {
            const resData = res.data

            if (resData.type == 'application/json') {
               const text = await resData.text()
               const json = await JSON.parse(text)
               Message({
                  showClose: true,
                  message: json.msg,
                  type: 'error'
               })
            } else {
               const blob = new Blob([res.data], {
                  type: 'application/zip'
               })

               const objectUrl = URL.createObjectURL(blob)
               const link = document.createElement('a')

               link.download = decodeURI(res.headers['content-disposition'].split('=')[1])
               link.href = objectUrl
               document.body.appendChild(link)
               link.click()
               document.body.removeChild(link) // 下载完成移除元素
               window.URL.revokeObjectURL(objectUrl) // 释放掉blob对象
            }
         })
   },

   excelDownLoad(url, data) {
      const JWT = 'Bearer ' + localStorage.getItem('kjcxfwlm_token')
      axios({
         method: 'post',
         url: baseURL + url,
         data,
         responseType: 'blob',
         headers: {
            Authorization: JWT,
            'Content-Type': 'application/json;charset=UTF-8'
         }// 设置header信息
      })
         .then(async res => {
            const resData = res.data
            // if (res.headers['content-type'] == 'application/json') {
            if (resData.type == 'application/json') {
               const text = await resData.text()
               const json = JSON.parse(text)
               Message({
                  showClose: true,
                  message: json.msg,
                  type: 'error'
               })
            } else {
               // res.data 是一个 blob 流格式
               const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
               const downloadElement = document.createElement('a')
               const href = window.URL.createObjectURL(blob)
               downloadElement.href = href
               downloadElement.download = decodeURI(res.headers['content-disposition'].split('=')[1])
               // downloadElement.download = res.headers['content-disposition'].split('=')[1]
               document.body.appendChild(downloadElement)
               downloadElement.click()
               document.body.removeChild(downloadElement) // 下载完成移除元素
               window.URL.revokeObjectURL(href) // 释放掉blob对象
            }
         })
   }
}

Vue.prototype.$http = http
export default http
