import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import '@/utils/http'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';//elementui css
import '@/assets/scss/element-variables.scss';//elementui主题修改
// import '@/assets/scss/index.scss' // global css
// 全局组件引入
import Title from '@/components/title.vue'
import Pagination from '@/components/Pagination.vue'
import EZUIKit from 'ezuikit-js';
import { selectDictLabel } from "@/utils/ruoyi";

axios.defaults.baseURL = '/prod-api/'
// axios.defaults.baseURL = '/proxy_url'


Vue.use(EZUIKit);
Vue.use(ElementUI);
Vue.use(axios);
// 全局组件挂载
Vue.component('Title', Title);
Vue.component('Pagination', Pagination);
Vue.config.productionTip = false
Vue.prototype.selectDictLabel = selectDictLabel
new Vue({
   router,
   store,
   render: h => h(App)
}).$mount('#app')
