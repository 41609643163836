<template>
  <div id="app" ref="app">
    <!-- 红色背景 -->
    <Bkg v-if="!$route.meta.yellowBkg && !$route.meta.notShowBkg">
      <router-view />
    </Bkg>
    <!-- 黄色背景 -->
    <Bkg v-else-if="$route.meta.yellowBkg && !$route.meta.notShowBkg"
      bkgImgSrc="https://dazhang-1255454858.cos.ap-nanjing.myqcloud.com/static/n-bkg.png">
      <template v-slot:titleImg>
        <img style="width: 35%;" src="https://dazhang-1255454858.cos.ap-nanjing.myqcloud.com/static/pureTitle.png">

      </template>
      <router-view />
    </Bkg>
    <!-- 其他背景 -->
    <router-view v-else-if="$route.meta.notShowBkg" />

  </div>
</template>
<script>
import Bkg from '@/components/bkg.vue'
export default {
  components: {
    Bkg
  },
  data() {
    return {

    }
  },
  created() {

  },
  methods: {

  }

}
</script>

<style lang="scss" >
:root {
  /* 设置基准字体大小 */
  font-size: 2vmin;
}

// #app {
//   font-family: ui-monospace;
//   text-align: center;

// }
</style>
